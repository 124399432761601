.bannerBackground {
    display: flex;
    background-color: white;
    justify-content: center;
}

.bannerImage {
    position: relative;
    max-width: 100%; /* Ensure the image doesn't exceed its container's width */
    height: 400px;   /* Set height to auto for responsive scaling */
}

.registerButton {
    background-color: #4CAF50;
    color: white;
    margin-left: 20px;
    border: 2px solid white;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 15px 15px;
    cursor: pointer;
    height: 45px;
    width: auto;
    padding: 10px;
    position: relative;
}

/* Media Query for Mobile */
@media only screen and (max-width: 767px) {
    .bannerImage {
        height: 150px; /* Adjust height for mobile screens */
    }
}
