.sub-button {
    height: auto;
}

.subheading {
    font-size: 1.0rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: gray
}

.promo-text {
    font-size: 1.0rem;
    font-weight: 600;
    margin-bottom: 0rem;
    color: rgb(168, 22, 22)
}

.promo-icon {
    font-size: 1.0rem;
    font-weight: 600;
    margin-bottom: 0rem;
    margin-top: 3px;
    margin-right: 5px;
    color: rgb(168, 22, 22)
}