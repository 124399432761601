.loader {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid fusia;
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.bg-loader {
    color: green;
}